import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import resourceListStyle from '../styles/resourceList.scss';
import { externalLink } from '../utils'

const SectionList = ({ data, style }) => {
    const getListingLabel = data.filter(item => item.servicesFields.listingButtonLabel)
    if (getListingLabel.length === 0) return '';
    return (
        <div className='' css={[css(resourceListStyle), css(style)]}>
            <div className='services-content-box box-selectors'>
                <div className='services-content-box-listings'>
                    {
                        data.map((serviceBox, index) => (
                            <div className='service-box-container' key={index}>
                                <div className='service-box'>
                                    <div className='service-box_image'>
                                        {
                                            serviceBox.servicesFields.listingButtonImage && (
                                                <div className='service-box_image-wrapper'>
                                                    {
                                                        serviceBox.servicesFields.listingButtonImage && <img src={serviceBox.servicesFields.listingButtonImage.sourceUrl} alt={serviceBox.servicesFields.listingButtonImage.altText} />
                                                    }
                                                </div>
                                            )
                                        }
                                        {
                                            serviceBox.servicesFields.listingButtonLabel && (
                                                <div className='service-box_description'>
                                                    <a href={serviceBox.servicesFields.externalLink || serviceBox.slug} {...serviceBox.servicesFields.externalLink && externalLink} >{serviceBox.servicesFields.listingButtonLabel}</a>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

const SectionListStyled = (props) => (
    <SectionList {...props} style={props.style} />
);

SectionList.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})),
    style: PropTypes.string
};

SectionList.defaultProps = {
    data: [],
    style: ''
};

SectionListStyled.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})),
    style: PropTypes.string
};

SectionListStyled.defaultProps = {
    data: [],
    style: ''
};

export default SectionListStyled;
