import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { useBreakpoint, withBreakpoints } from 'gatsby-plugin-breakpoints';
import { css } from '@emotion/react';
import Layout from '../components/Layout';
import HeroImage from '../components/HeroImage';
import HeroImageText from '../components/HeroImageText';
import ResourceList from '../components/ResourceList';
import BreadCrumb from '../components/Breadcrumb';
import SectionList from '../components/SectionList'
import Seo from '../components/Seo';
import resourceListStyle from '../styles/resourceList.scss';

const NavigatorSubPage = ({ data, style }) => {
  const breakpoints = useBreakpoint();
  const {
    allWpService: {
      nodes: [
        {
          title = '',
          uri = '',
          content,
          servicesFields: {
            headerBackgroundImage,
            headerBackgroundImageMobile,
            resourceSection,
          } = {},
          ancestors,
        } = {},
      ] = [],
    } = {},
  } = data;

  const service = data.allWpService?.nodes[0] || {};
  const image = !breakpoints.sm ? (headerBackgroundImage?.sourceUrl || '') : (headerBackgroundImageMobile?.sourceUrl || '');
  const section = service.wpChildren?.nodes.sort(function (a, b) {
    let dateA = new Date(a.date), dateB = new Date(b.date);
    return dateA - dateB;
  });
  const displaySection = section?.filter(field => field.servicesFields.listingButtonLabel);
  const directPath = ancestors?.nodes[0].title ? [{ url: ancestors?.nodes[0].uri, label: ancestors?.nodes[0].title }, { url: uri, label: title }] : [{ url: uri, label: title }]

  return (
    <Layout style={style}>
      <Seo title={title} />
      <div css={css(resourceListStyle)}>
        {
          !breakpoints.sm ? (
            image ? (
              <HeroImage image={image}>
                <div className='heroimage-text-breadcrumb-container'>
                  <div className='breadcrumb-description'>
                    <div className='breadcrumb-description-text'>
                      {uri && <h1>{title}</h1>}
                      {uri && <BreadCrumb directPath={directPath} />}
                    </div>
                  </div>
                </div>
              </HeroImage>
            ) :
              <HeroImageText>
                <div className='breadcrumb-description-text-only'>
                  {title && <h2>{title}</h2>}
                  {uri && <BreadCrumb directPath={directPath} />}
                </div>
              </HeroImageText>
          ) : (
            <>
              {
                image && <HeroImage image={image} ></HeroImage>
              }
              <HeroImageText>
                <div className='breadcrumb-description-text-only'>
                  {title && <h2>{title}</h2>}
                  {uri && <BreadCrumb directPath={directPath} />}
                </div>
              </HeroImageText>
            </>
          )
        }
        {
          (content || (displaySection && displaySection.length > 0)) && (
            <div className="page-main section-list">
              {
                content && (
                  <div className="services-content">
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                  </div>
                )
              }
              {
                (section && section.length > 0) && <SectionList data={section} />
              }
            </div>
          )
        }
        {resourceSection && resourceSection.length > 0 && <ResourceList data={resourceSection} />}
      </div>
    </Layout>
  )
}

export const query = graphql`
query($uri: String!) {
  allWpService(filter: {uri: {eq: $uri}}) {
    nodes {
      id
      content
      uri
      title
      slug
      link
      parentId
      wpChildren {
        nodes {
          slug
          uri
          link
          id
          date
          ... on WpService {
            date
            servicesFields {
              externalLink
              headerBackgroundImage {
                altText
                title
                sourceUrl
              }
              headerBackgroundImageMobile {
                altText
                title
                sourceUrl
              }
              listingButtonLabel
              resourceSection {
                resourceSectionTitle
                resourceList {
                  icon {
                    sourceUrl
                    altText
                  }
                  title
                  buttonText
                  buttonUrl
                  text
                }
              }
              listingButtonImage {
                altText
                title
                sourceUrl
              }
            }
          }
        }
      }
      servicesFields {
        externalLink
        headerBackgroundImage {
          altText
          title
          sourceUrl
        }
        headerBackgroundImageMobile {
          altText
          title
          sourceUrl
        }
        listingButtonLabel
        resourceSection {
          scrollbar
          resourceSectionTitle
          resourceList {
            icon {
              sourceUrl
              altText
            }
            navigatoricon {
              sourceUrl
              altText
            }
            title
            buttonText
            buttonUrl
            text
            navigatorButtonType
            linkType
            buttonPage { 
              ... on WpService {
                slug
                uri
              }
              ... on WpPage {
                slug
                uri
              }
            }
            buttonMultipleExternalLinksPage {
              link
              linkLabel
            }
          }
        }
        listingButtonImage {
          altText
          title
          sourceUrl
        }
      }
      ancestors {
        nodes {
          ... on WpService {
            title
            uri
          }
        }
      }
    }
  }
}
`;
const style = `
  .page-main {
    padding: 0 4.0875em;
    @media screen and (max-width: 992px) {
      padding: 0;
      margin: 0 auto;
    }
  }
  .page-main-container {
    padding-bottom: 50px;
  }
  @media screen and (max-width: 992px) {
    .menu-section {
      padding-bottom: 0 !important;
    }
  }
  .breadcrumb-description-text-only {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 4.0875em !important;
    margin-top: 2.2em;
    margin-bottom: 2.2em !important; 
    h1 {
        margin-bottom: 0.8em;
    }
    .breadcrumb {
        ul {
            li, a {
                color: black;
            }
        }
    }
    @media screen and (max-width: 992px) {
        padding: 0 !important;
    }
  }
  .services-content {
    @media screen and (max-width: 992px) {
      padding-top: 0;
    }
    h2 {
      margin-top: 0;
    }
    strong +  h2, p + h2 {
      margin-top: 1.45em;
    }
  }
`
const NavigatorSubPageStyled = (props) => (
  <NavigatorSubPage {...props} style={style} />
);

NavigatorSubPage.propTypes = {
  data: PropTypes.shape({}),
  style: PropTypes.string
};

NavigatorSubPage.defaultProps = {
  data: {},
  style: ''
};

NavigatorSubPageStyled.propTypes = {
  data: PropTypes.shape({})
};

NavigatorSubPageStyled.defaultProps = {
  data: {}
};

export default withBreakpoints(NavigatorSubPageStyled);
